@import "../../styles/main.scss";
@import 'node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';
// client/node_modules/hamburgers/_sass/hamburgers/hamburgers.scss
* {
  margin: 0;
  padding: 0;
}
.mob-nav {
  z-index: 50000;
  position: fixed; //!
  min-height: 1rem; //* don't change from min for the dropdown
  width:100vw;

  top: 0;
  // position: relative;
  background-color: $lighterBlue;
  // display: flex;
  // justify-content:space-between;

  .dropdown {
    display: flex;
    flex-direction: column;
    text-align: left;
    // margin-top: 2rem;
    // border-top: 1px black solid;
    padding-top: 1rem;
    padding: 4px;
    
    text-decoration: none;
    color: $textColor;
    transition: transform 0.3s ease;
    box-shadow: 0px 0px 5px 0px $hoverBlue;

  }
  a {
    padding: 0.2rem;
    font-size:2rem;
    // margin: 0 10px;
    text-decoration: none;
    color: $textColor;
    transition: transform 0.3s ease;
  }
}

//reg

.hide {
  display: none !important;
}

.burger{
  padding: 1.5rem;
  padding-left: .5rem;
  
}

.hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity,filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
}