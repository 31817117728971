@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
// @import "bulma/sass/utilities/_all.sass";
// @import "bulma/sass/grid/columns.sass";
$lighterBlue: #1e49d4;
$lightBlue: #1e49d4;
$darkBlue: #0d3e8a;
$green: #1ed460;
$black: #01050a;
$backgroundColor: #0d3e8a;
$textColor: $green;
$sectionsBg: #2867c5ca;
$sectionsBgDark: #1d3683;
// $greenBG: rgba(31, 83, 92, 0.9);
// $textColor: $green;
// $backgroundColor: #1f1522;
$hoverBlue: rgb(0, 255, 242);

// @media only screen and (max-width: 768px) {}

.hide {
  display: none;
}
#about {
  scroll-margin-top: 5rem;
}
#projects {
  scroll-margin-top: 4rem;
}
#tech {
  scroll-margin-top: 5rem;
}
#experience {
  scroll-margin-top: 5rem;
}
#contact {
  scroll-margin-top: 5rem;
}
#more-about {
  scroll-margin-top: 10rem;
}

body {
  max-width: 100vw;
  // height: 100vh;
  overflow: auto;
  overflow-x: hidden !important;
  font-family: "Varela Round", sans-serif;
  color: $textColor;
  overscroll-behavior: none;
  // width: 100vw;
  margin: 0;
  // background-color: $backgroundColor;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgb(0, 0, 0) 10%,
    rgba(9, 75, 205, 1) 63%,
    rgba(4, 149, 232, 1) 85%,
    rgba(0, 162, 179, 1) 100%
  );
}
.navbar {
  z-index: 1000;
  position: fixed; //!
  top: 0; //!
  min-width: 100vw;
  height: 7%;
  // overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(53, 7, 7);
  background-color: $lighterBlue;
  // border-bottom-right-radius: 80%;
  // border-bottom-left-radius: 80%;
  box-shadow: 0px 0px 5px 0px $hoverBlue;
  .navbar-items {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    a {
      padding: 4px;
      margin: 0 10px;
      font-size: 1.6rem;

      text-decoration: none;
      color: $textColor;
      transition: transform 0.3s ease;
    }
    a:hover {
      transform: scale(1.2);
      color: $hoverBlue;
    }
  }

  // @media only screen and (max-width: 768px) {
  //   // @import "bulma/sass/grid/columns.sass";
  //   .navbar-items{
  //     display:none;
  //   }


  // }
}







.homepage {
  margin-top: 3rem;
  a {
    text-decoration: none;
    color: $textColor;
  }
}

.hero {
  height: 100vh;
  .header {
    text-align: center;
    h1,
    h2 {
      margin: 0;
    }
    h1 {
      font-size: 50px;
    }
    h2 {
      font-size: 40px;
    }
    .down-arrow-btn {
      font-size: 90px;
      margin: 0 auto;
      width: 90px;
    }
    .down-arrow-btn:hover {
      transform: scale(1.8);
      cursor: pointer;
    }
  }
  .typist-container {
    // z-index: 50;
    // position: relative;
    padding: 1rem;
    font-family: Menlo, Monaco, "Courier New", monospace;
    color: $textColor;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    .desktop-img {
      z-index: 50;
      border-radius: 7px;
    }
    .Typist {
      margin: 0 auto;
      color: rgb(0, 0, 0);
      z-index: 500;
      position: relative;
      text-align: center;
      margin-left: -3rem;
      position: absolute;
      min-width: 80%;

      font-size: 1.7rem;
      padding: 1rem;
    }
    .Typist .Cursor {
      display: inline-block;
      color: $textColor;
      &--blinking {
        opacity: 1;
        animation: blink 1s linear infinite;
        @keyframes blink {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
    }
  }
}
.about-section {
  z-index: 500;
  position: relative;
  min-height: 20rem;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 50px;
  margin: 0 auto;
  margin-bottom: 2rem;
  padding: 2rem;
  width: 80vw;
  background-color: $sectionsBg; //!
  // background-color: #3f51b5; //!
  border-radius: 50px;
  box-shadow: 0px 0px 10px 0px $hoverBlue;

  h1 {
    font-size: 50px;
  }
  .brand-statement {
    font-weight: 50;
    // color: #548681e5;
    h1 {
      width: 100%;
      text-align: left;
    }
    p {
      padding: 1rem;
      font-size: 20px;
      text-align: justify;
      margin: 0;
      width: 80vw;
    }
    a {
      text-decoration: none;
      color: $textColor;
      text-align: center;
    }
    a:hover {
      color: $hoverBlue;
    }
  }
}

.projects {
  z-index: 500;
  position: relative;
  // padding: 2rem;
  margin-top: 10vh;
  margin-bottom: 10vh;
  text-align: center;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  .project-header {
    padding-bottom: 1rem;

    font-size: 50px;
    // background-color: rgba(9, 39, 135, 0.612)
  }

  p {
    margin: 0;
    margin-bottom: 2rem;
    font-size: 26px;
    padding: 1rem;
  }
  .project-details-container {
    z-index: 500;
    position: relative;
    width: 85%;
    margin: 0 auto;
    margin-top: 5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $sectionsBg;
    // border: 2px $green dashed;
    border-radius: 50px;
    box-shadow: 0px 0px 10px 0px $hoverBlue;
    border-radius: 50px;
    background-color: #3f51b578;
    box-shadow: 0px 0px 10px 0px $hoverBlue;
    h1 {
      font-size: 50px;
      background: none;
    }
    built-with-icon h1 {
      z-index: 500;
      position: relative;
      margin: 0;
    }
    img {
      width: 70%;
      border-radius: 10px;
      transition: transform 0.6s ease;
      // border: 2px $textColor solid;
      box-shadow: 0px 0px 10px 0px $textColor;
    }
    img:hover {
      transform: scale(1.1);
      box-shadow: 0px 0px 10px 0px $hoverBlue;
    }
    .devicon-project {
      fill: $black;
      height: 60px;
      margin-bottom: -10px;
      transition: transform 0.3s ease;
    }
    .devicon-project:hover {
      transform: scale(1.2);
      fill: $hoverBlue;
    }
    .built-with-icon {
      width: 70px;
      margin: 0 1rem;
    }
    .built-with-icon:hover {
      box-shadow: none;
      transform: none;
    }
    .express-icon-small {
      height: 50px;
      fill: black;
      border: none;
      box-shadow: none;
      // transform: scale(1.2);
    }
  }
  .monomelody {
    // background-color: rgba(223, 243, 2, 0.829);
    margin-top: 0rem;

    @media only screen and (max-width: 768px) {
      h1 {
        font-size: 2.4rem;
      }
    }
    img {
      box-shadow: 0px 0px 10px 0px orange;
    }
  }
  .doodler {
    // text-align: right;
    .doodler-img {
      box-shadow: 0px 0px 10px 0px rgb(0, 255, 166);
    }
  }
  .hungry-shark {
    // text-align: right;
    img {
      box-shadow: 0px 0px 10px 0px rgb(255, 0, 0);
    }
  }
  @media only screen and (max-width: 768px) {
    .project-details-container {
      width: 80%;
      h1 {
      }
    }
    //!________________________________for responsiveness___________
    @media only screen and (max-width: 768px) {
      .project-details-container {
        width: 80%;
      }
    }
    //!---------------------------------------------------------------
  }
}
.tech {
  z-index: 500;
  position: relative;
  color: $textColor;
  margin: 0 auto;
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  ol {
    width: 70vw;
    padding: 0%;
    list-style: none;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  li {
    z-index: 500;
    position: relative;
    margin: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    transition: transform 0.3s ease;
    fill: $textColor;
    width: 6rem;
    // border: 1px red solid;
    img {
      height: 96px;
    }
  }
  li:hover {
    // border: 1px red solid;
    transform: scale(1.5);
  }
  .devicon {
    color: $textColor;
    fill: $green;
    // background-color:#2867c5f2;
    // border-radius:  10px;
    // fill: gold;
    // width: 6rem;
  }
}
.experience {
  z-index: 500;
  position: relative;
  width: 80vw;
  margin: 0 auto;
  margin-top: 5rem;
  text-align: center;
  .xp-detail {
    display: relative;
    z-index: 1500;
    // text-align: justify;
    background-color: $sectionsBgDark; //!
    // background-color: #3f51b5; //!
    border-radius: 50px;
    box-shadow: 0px 0px 10px 0px $hoverBlue;
    margin: 0 auto;
    margin-top: 2rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
    h2 {
      margin: 0;
    }
    p {
      margin: 0 auto;
      padding: 10px;
    }
  }
}
.contact {
  // * {border: 0.002rem rgb(255, 0, 221) solid;}

  z-index: 500;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;

  min-height: 20vh;
  width: 80%;
  padding: 1rem 0;
  margin: 0 auto;
  margin-bottom: 20vh; //!
  margin-top: 20vh;
  border-radius: 50px;
  background-color: $sectionsBgDark;
  box-shadow: 0px 0px 10px 0px $hoverBlue;

  .linkedin-img {
    margin: 0 auto;
    position: relative;
    width: 15rem;
    margin-top: 2rem;
    border-radius: 50px;
    filter: brightness(0.7);
  }
  .contact-items {
    height: 80%;
    margin: 0 auto;

    h1 {
      font-size: 40px;
      text-align: center;
      margin-top: 0;
    }
    .contact-buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .icon-contact {
        width: 70px;
        fill: $green;
        transition: transform 0.3s ease;
        margin-left: 2rem;
        margin-right: 2rem;
      }
      .icon-contact:hover {
        transform: scale(1.4);
        fill: $hoverBlue;
      }
      .github-icon-contact {
        width: 80px;
        margin-top: -0.9rem;
      }
      .twitter-icon:hover {
        color: $hoverBlue;
        background-color: $hoverBlue;
        fill: $hoverBlue;
      }
      .email-icon-container {
        color: red;
        text-align: center;
        margin-top: 1.5rem;

        @media only screen and (max-width: 768px) {
          h4 {
            display: none;
          }
        }

        h4 {
          margin: 0 auto;
          color: rgba(0, 0, 0, 0);
        }
      }
      .email-icon-container:hover {
        h4 {
          color: $hoverBlue;
        }
      }
    }

    @media only screen and (max-width: 768px) {
    }
  }
}
.more-about {
  z-index: 500;
  position: relative;
  z-index: 500;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20vh; //!
  color: $textColor;
  p {
    font-size: 20px;
    width: 60%;
    margin: 0 auto;
    text-align: justify;
  }
  .coder {
    height: 20px;
    width: auto;
  }
  @media only screen and (max-width: 768px) {
    p {
      text-align: center;
    }
    @media only screen and (max-width: 768px) {
      p {
        font-size: 20px;
        width: 100%;
      }
    }
  }
}
// .z-index {
//   position: relative;
//   z-index: 10000 !important;
// }
.fish {
  position: absolute;
  z-index: 2;
  width: 100%;
  img {
    width: 10rem;
  }
}
.fish-right {
  text-align: end;
}
.shark {
  // border: 1px solid red;
  filter: brightness(0.7);
  padding-top: 17px;
  padding-right: 50px;
  text-align: left;
  margin-left: 2rem;
}
.reverse-shark {
  img {
    transform: scaleX(-1);
  }
}
.jellyfish {
  filter: brightness(150%) opacity(0.6);
}
.nyan-container {
  position: relative;
  z-index: 5000;
}
.sub {
  filter: brightness(0.6);
}
.blow-fish {
  filter: brightness(0.5);
}
.bottom-squid {
  position: absolute;
  // margin-top: 39rem;
  bottom: -10px;
  right: 0;
  filter: brightness(50%);
}
.footer {
  position: relative;
  // bottom: 0;
  z-index: 1000;
  height: 7vh;
  width: 100vw;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $black;
  border-top: rgb(0, 0, 162) 1px solid;
  border-width: 2px;
  .footer-items {
    text-align: center;
    margin: 0;
    padding: 0;
    h2 {
      margin: 0%;
    }
  }
}

body {
  overflow-x: hidden !important;
}
html,
body {
  overflow-x: hidden;
}
body {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .fish {
    position: absolute;
    z-index: 2;
    width: 40%;
    img {
      width: 6rem;
    }
  }
}

.particles-wrapper {
  position: absolute;
  width: 100vw;
  height: 10000em;
  max-height: 5700px;
  top: 0;
  z-index: 0;
}

@media only screen and (min-width: 915px) {
  .particles-wrapper {
    height: 10000em;
    max-height: 5700px;
  }
}
